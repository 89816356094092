import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, Text, Span } from "../../components/Core";
import imgPhoto from "../../assets/image/png/portfolio-about.png";

const LinkSocial = styled.a`
  color: ${({ theme }) => theme.colors.light} !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1.63px;
  transition: 0.4s;
  &:hover {
    color: ${({ theme }) => theme.colors.light} !important;
    transform: translateY(-5px);
  }
`;

const About = ({ hero = true, bg = "dark", ...rest }) => {
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div>
                <img src={imgPhoto} alt="vienna-app.dev" className="img-fluid" />
              </div>
            </Col>
            <Col lg="6">
              <div className="pl-lg-4 pt-5 pt-lg-0">
                <Title color="light" variant="secSm">
                  About
                </Title>
                <Text
                  color="light"
                  className="mt-3 mt-lg-5"
                  css={`
                    line-height: 1.5;
                  `}
                >
                  vienna-app.dev is a community showcasing the amazing work of mobile app developers around the city Vienna, created by techprimate in 2021.
                </Text>
                <Text color="light" className="mt-4">
                  Inspired by the Silicon Valley and the startup city Berlin, we strongly believe in the potential of an Austrian developer community.
                </Text>
                <Text color="light" className="mt-4">
                  It's main objective is highlighting beautiful apps and giving indie devs a platform to reach others, so we can grow into a strong network together.
                </Text>
                <Text color="light" className="mt-4" variant="small">
                  Best regards,
                </Text>
                <Text color="light" className="mt-1 font-weight-bold">
                  Phil &amp; Jules
                </Text>
                <Text color="light" variant="small">
                  Co-Founders of vienna-app.dev
                </Text>
                <div className="mt-5 d-flex">
                  <LinkSocial
                    href="https://www.twitter.com/techprimate"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" mr-3"
                  >
                    Twitter
                  </LinkSocial>
                  <LinkSocial
                    href="https://www.techprimate.com/impressum"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    Imprint
                  </LinkSocial>
                  <LinkSocial
                    href="https://www.techprimate.com/dse"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    Privacy Policy
                  </LinkSocial>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default About;
